// conscia-service.js
import {CONSCIA_API_ROOT, CONSCIA_KEY} from '../constants';
import {useCustomerId, useCustomerBaskets} from '@salesforce/commerce-sdk-react';
import {isServer} from '@salesforce/retail-react-app/app/utils/utils';
import Cookies from 'js-cookie';

const getData = async(getType, getKey, context) => {
  try {
    let body = {
      context,
      debug: false, // set this false to be less verbose
    };

    if(getType == 'template') {
      body[`${getType}Code`] = getKey;
    }
    else if(getType == 'components') {
      body['componentCodes'] = getKey;
    }
    else {
      body[`${getType}Codes`] = getKey;
    }

    const response = await fetch(`${CONSCIA_API_ROOT}/${getType}/_query`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${CONSCIA_KEY}` ,
        'x-customer-code': 'sandbox-salesforce',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
    // return data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};

const getTemplate = async (templateCode, context) => {
  return await getData('template', templateCode, context);
}

const getComponents = async (componentCodes, context) => {
  return await getData('components', componentCodes, context);
}

const useCurrentBasket = ({id = ''} = {}) => {
  const customerId = useCustomerId()
  const {data: basketsData, ...restOfQuery} = useCustomerBaskets(
      {parameters: {customerId}},
      {
          enabled: !!customerId && !isServer
      }
  )

  const currentBasket =
      basketsData?.baskets?.find((basket) => basket?.basketId === id) || basketsData?.baskets?.[0]

  return {
      ...restOfQuery,
      data: currentBasket,
      derivedData: {
          hasBasket: basketsData?.total > 0,
          totalItems:
              currentBasket?.productItems?.reduce((acc, item) => acc + item.quantity, 0) || 0
      }
  }
}

const getCustomerSegment = () => {
  return Cookies.get('customerSegment') || '';
}

const getCustomerId = () => {
  return Cookies.get('externalId') || '';
}

export { 
  getTemplate, 
  getComponents, 
  useCurrentBasket, 
  getCustomerSegment,
  getCustomerId
};

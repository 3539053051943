import React, {useEffect, useState} from 'react'
import {Box, Center} from '@salesforce/retail-react-app/app/components/shared/ui'
import {useCurrentCustomer} from '@salesforce/retail-react-app/app/hooks/use-current-customer'

import Section from '@salesforce/retail-react-app/app/components/section'
import {getComponents} from '../../conscia/conscia-service'
import {Table, Thead, Tbody, Tr, Th, Td} from '@chakra-ui/react'
const onClient = typeof window !== 'undefined'
//Created date, subject, priority, description, status
const AccountCases = () => {
    const {
        data: {email}
    } = useCurrentCustomer()
    const [isData, setIsData] = useState(false)
    const [caseData, setCaseData] = useState([])
    const [isError, setIsError] = useState(false)
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    console.log(email)
    useEffect(() => {
        setIsLoading(true)
        getComponents(['salesforce-core-get-cases'], {email})
            .then((res) => {
                setIsLoading(false)
                setIsData(true)
                setCaseData(res.components['salesforce-core-get-cases'].response.records)
            })
            .catch((err) => {
                setIsLoading(false)
                setIsError(true)
                setError(err)
            })
    }, [email])

    return (
        <Center>
            <Box>
                <Section title="Track Your Case Status">
                    {isData ? (
                        <Table variant="striped">
                            <Thead>
                                <Tr>
                                    <Th>Case Number</Th>
                                    <Th>Subject</Th>
                                    <Th>Priority</Th>
                                    <Th>Description</Th>
                                    <Th>Closed</Th>
                                    <Th>Escalated</Th>
                                    <Th>Created Date</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {caseData.map((cased) => (
                                    <Tr key={cased.Id}>
                                        <Td>{cased.CaseNumber}</Td>
                                        <Td>{cased.Subject}</Td>
                                        <Td>{cased.Priority}</Td>
                                        <Td>{cased.Description}</Td>
                                        <Td>{cased.IsClosed ? 'Yes' : 'No'}</Td>
                                        <Td>{cased.IsEscalated ? 'Yes': 'No'}</Td>
                                        <Td>{new Date(cased.CreatedDate).toDateString()}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    ) : null}
                </Section>
            </Box>
        </Center>
    )
}

AccountCases.getTemplateName = () => 'account-cases'

export default AccountCases
